import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68')
];

export const server_loads = [0];

export const dictionary = {
		"/(app)": [~18,[3],[4]],
		"/(app)/about/become-a-driver": [~20,[3],[4]],
		"/(app)/about/become-a-partner": [~21,[3],[4]],
		"/(app)/about/careers": [~22,[3],[4]],
		"/(app)/about/contact": [23,[3],[4]],
		"/(app)/about/corporate-vouchers": [~24,[3],[4]],
		"/(app)/about/faq": [~25,[3],[4]],
		"/(app)/about/groceries": [26,[3],[4]],
		"/(app)/about/policy/driver-privacy": [~27,[3],[4]],
		"/(app)/about/policy/partner-privacy": [~28,[3],[4]],
		"/(app)/about/policy/privacy": [~29,[3],[4]],
		"/(app)/about/policy/responsible-disclosure": [~30,[3],[4]],
		"/(app)/about/snackme": [~31,[3],[4]],
		"/(app)/about/snackme/friend": [32,[3],[4]],
		"/(app)/about/snackme/gift": [33,[3],[4]],
		"/(app)/about/snackme/gift/request": [34,[3],[4]],
		"/(app)/about/takealotmore": [~35,[3],[4]],
		"/(app)/about/terms": [~36,[3],[4]],
		"/(app)/buy-again": [37,[3],[4]],
		"/(admin)/cms": [8,[2]],
		"/(admin)/cms/areas": [9,[2]],
		"/(admin)/cms/brands": [10,[2]],
		"/(admin)/cms/editable/home": [11,[2]],
		"/(admin)/cms/images": [12,[2]],
		"/(admin)/cms/inserts": [13,[2]],
		"/(admin)/cms/map": [~14,[2]],
		"/(admin)/cms/restaurants": [15,[2]],
		"/(admin)/cms/settings": [16,[2]],
		"/(admin)/cms/tags": [17,[2]],
		"/(app)/contact": [~38,[3],[4]],
		"/(app)/delivery/best-restaurants": [~41,[3],[4,6]],
		"/(app)/delivery/in/[...area]": [~42,[3],[4,6]],
		"/(app)/delivery/of/[tag]": [~43,[3],[4,6]],
		"/(app)/delivery/restaurants-near-me/[[page]]": [~44,[3],[4,6]],
		"/(app)/delivery/[vertical=seoVertical]/[slug]-near-me": [~39,[3],[4,6]],
		"/(app)/delivery/[vertical=vertical]/[name]/[id]": [~40,[3],[4,6]],
		"/env": [65],
		"/env/platform": [66],
		"/env/push": [67],
		"/env/releasenotes": [68],
		"/(app)/gifting": [45,[3],[4]],
		"/(app)/gifting/gifts": [46,[3],[4]],
		"/(app)/help": [~47,[3],[4]],
		"/(app)/help/faqs": [~48,[3],[4]],
		"/(app)/help/zendesk": [49,[3],[4]],
		"/(app)/id": [50,[3],[4]],
		"/(app)/info/[page]": [~51,[3],[4]],
		"/(app)/profile": [52,[3,7],[4]],
		"/(app)/profile/cards": [53,[3,7],[4]],
		"/(app)/profile/me": [54,[3,7],[4]],
		"/(app)/profile/orders": [55,[3,7],[4]],
		"/(app)/profile/smartshopper": [56,[3,7],[4]],
		"/(app)/profile/wallet": [57,[3,7],[4]],
		"/(app)/reset-password/[token]": [58,[3],[4]],
		"/(app)/signin": [59,[3],[4]],
		"/(app)/simulate/[vertical=vertical]/order/details": [60,[3],[4]],
		"/(app)/styleguide": [61,[3],[4]],
		"/(order)/[vertical=vertical]/order/[id]/checkout": [62],
		"/(order)/[vertical=vertical]/order/[id]/checkout/[outcome]": [63],
		"/(order)/[vertical=vertical]/order/[id]/driver": [64],
		"/(app)/[vertical]/order/[id]/details": [19,[3,5],[4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';